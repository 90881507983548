import React from "react";
import "./orderSummary.scss";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../components/header/Header";
import axios from "axios";
import { ApiBaseUrl } from "../../../../BaseUrl";

const OrderSummary = () => {
  // var accessToken = localStorage.getItem("ACCESS_TOKEN");
  const navigate = useNavigate();
  var invoice_tax = sessionStorage.getItem("invoice_tax");
  var invoice_total_amount = sessionStorage.getItem("invoice_total_amount");
  var invoice_sevice = sessionStorage.getItem("invoice_sevice");
  var name = sessionStorage.getItem("name");
  var email = sessionStorage.getItem("email");
  var mobile = localStorage.getItem("mobile");
  var city = sessionStorage.getItem("city");
  var state = sessionStorage.getItem("state");
  var payment_longurl = sessionStorage.getItem("payment_longurl");
  var start_date = sessionStorage.getItem("start_date");
  var woofily_tax = sessionStorage.getItem("Woofily_tax");
  var end_date = sessionStorage.getItem("end_date");
  var SGST = sessionStorage.getItem("SGST");
  var CGST = sessionStorage.getItem("CGST");
  var IGST = sessionStorage.getItem("IGST");

  // const paynowbutton = () => {
  //   const options = {
  //     key: "rzp_live_GmyK4XZdK8ijXT",
  //     amount: invoice_total_amount * 100, // 2000 paise = INR 20, amount in paisa
  //     name: "Woofily",
  //     description: "Purchase Description",
  //     image: "image/woofilylogin.png",
  //     handler: function (response) {
  //       // alert(response.razorpay_payment_id);
  //       sessionStorage.setItem("Payment_Id", response.razorpay_payment_id)
  //       navigate("/my_bookings")
  //     },
  //     prefill: {
  //       name: name,
  //       email: email,
  //     },
  //     notes: {
  //       address: "Hello World",
  //     },
  //     theme: {
  //       color: "#FECA05",
  //     },
  //   };

  //   const rzp = new window.Razorpay(options);
  //   rzp.open();
  // };
  

  const payNowAction = () => {
    // window.open(sessionStorage.getItem("short_url"));
    window.location.href = sessionStorage.getItem("short_url");
  };

  const payLaterButton = () => {
    var Host_ID = localStorage.getItem("HOST_ID");
    var PARENT_ID = localStorage.getItem("PARENT_ID");
    var Order_Id = sessionStorage.getItem("order_id");
    var accessToken = localStorage.getItem("ACCESS_TOKEN");

    if (!accessToken) {
      toast.error("Access token is missing");
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios
      .put(
        `${ApiBaseUrl}/parent/${PARENT_ID}/${Host_ID}/${Order_Id}/pay_later`,
        null,
        config
      )
      .then((response) => {
        toast.success("Your booking has been confirmed.");
        setTimeout(() => {
          navigate("/my_bookings");
        }, 1500);
      })
      .catch((error) => {
        // Handle errors
        if (error.response) {
          toast.error(
            `Error: ${error.response.status} - ${error.response.data.message}`
          );
        } else if (error.request) {
          toast.error("No response received from the server");
        } else {
          toast.error("Error occurred while sending the request");
        }
      });
  };

  return (
    <>
      <ToastContainer />
      <Header />
      <div className="ordersummary" style={{ paddingTop: "120px" }}>
        <div className="ordersummary_container">
          <div className="order_sum_flex1">
            <div className="order_sum_flex1-inner">
              <div className="order_summmary_heading">
                <div className="booking-name">
                  <h3>Booking Details</h3>
                </div>
                {/* <div className='booking_nos'>
                                    <p>Booking no.s</p>
                                    <p>{order_id}</p>
                                </div> */}
              </div>
              <div className="order_summmary_info">
                <div className="booking-name">
                  <div className="booking-name-info">
                    <p>Name</p>
                    <h6>{name}</h6>
                  </div>
                  <div className="booking-name-info">
                    <p>Email</p>
                    <h6>{email}</h6>
                  </div>
                </div>
                <div className="booking-start-date">
                  <div className="booking-date">
                    <p>Start Date</p>
                    <p>{start_date} </p>
                  </div>
                  <div className="booking-date">
                    <p>End Date</p>
                    <p>{end_date}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="order_sum_flex1_content">
              <div className="order">
                <h6>Phone Number</h6>
                <h6>{mobile}</h6>
              </div>
              {/* <div className='order'>
                                <h6>Address</h6>
                                <h6>{address1}</h6>
                            </div> */}
              <div className="order">
                <h6>City</h6>
                <h6>{city}</h6>
              </div>
              <div className="order">
                <h6>State</h6>
                <h6>{state}</h6>
              </div>
            </div>
          </div>
          <div className="order_sum_flex2">
            <div className="order_sum_flex2_container">
              <h3>Order Summary</h3>
              <div className="order_sum_flex2_content">
                <div className="order">
                  <h4>Total Amount</h4>
                  <h4>Rs {invoice_total_amount}</h4>
                </div>
                <div className="order">
                  <h5>Service</h5>
                  <h5>Rs {invoice_sevice}</h5>
                </div>
                {/*   */}
                <div className="order">
                  <h5>IGST</h5>
                  <h5>Rs {IGST}</h5>
                </div>
                <div
                  className="order"
                  style={{ fontSize: "14px", marginTop: "0" }}
                >
                  <p>(CGST @9% + SGST @9%)</p>
                </div>

                <div className="order">
                  <h5>Discount</h5>
                  <h6>Rs 00.00</h6>
                </div>
              </div>
              <div className="mb-4 order_button">
                <button style={{color: '#000'}} onClick={payNowAction} className="b me-3">
                  Pay Now
                </button>
                {/* <button onClick={payLaterButton} className="b">
                  Pay Later
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSummary;
