import React from "react";
import "./paymentFailure.scss";
import { NavLink } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const PaymentFailure = () => {
  const navigate = useNavigate();
  const Host_Id = localStorage.getItem("HOST_ID");
  return (
    <>
      <section className="payment_failed">
        <div className="info_status">
          <img src="/assets/images/PaymentFailedNoText.png" alt="" />
          <h1>Payment Failed!</h1>
        </div>
        <div className="navigation">
          <button
            onClick={() => {
              navigate(`/host/${Host_Id}`);
            }}
          >
            Try Again
          </button>
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            Go to Home
          </button>
        </div>
      </section>
    </>
  );
};

export default PaymentFailure;
