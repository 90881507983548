import React, { useEffect } from "react";
import "./paymentSuccess.scss";
import { NavLink } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PaymentFailure from "./PaymentFailure";
import { ApiBaseUrl } from "../../../../BaseUrl";
import Loading from "../../components/loading/Loading";

const PaymentSuccess = () => {
  // Get the URL search parameters
  const searchParams = new URLSearchParams(window.location.search);

  // Extract payment status from the query parameters
  const paymentStatus = searchParams.get("razorpay_payment_link_status");
  const PAYMENT_ID = searchParams.get("razorpay_payment_link_id");
  const PARENT_ID = localStorage.getItem("PARENT_ID");
  const HOST_ID = localStorage.getItem("HOST_ID");
  const Amount = sessionStorage.getItem("paymentamount_aftertax");
  const Order_ID = sessionStorage.getItem("order_id");
  const navigate = useNavigate();
  useEffect(() => {
    // Retrieve the access token from local storage
    const accessToken = localStorage.getItem("ACCESS_TOKEN");

    // Set the bearer token in the request headers
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    if (paymentStatus) {
      axios
        .get(
          `${ApiBaseUrl}/parent/${PARENT_ID}/${HOST_ID}/orderstatus/${PAYMENT_ID}`,
          config
        )
        .then((res) => {
          const CompleteOrderData = {
            order_id: Order_ID,
            payment_id: PAYMENT_ID,
            razonpay_order_id: res.data.order_id,
            amount: Amount,
            status: 1,
          };
          axios.post(
            `${ApiBaseUrl}/parent/${PARENT_ID}/${HOST_ID}/complete_order`,
            CompleteOrderData,
            config
          );
        });
    }
  }, []);

  return (
    <>
      <div>
        {paymentStatus === "paid" ? (
          <section className="payment_success">
            <div className="info_status">
              <img src="/assets/images/PaymentSuccessNoText.png" alt="" />
              <h1>Payment Successful!</h1>
            </div>
            <div className="navigation">
              <button
                onClick={() => {
                  navigate("/my-bookings");
                }}
              >
                My Bookings
              </button>
              <button
                onClick={() => {
                  navigate("/");
                }}
              >
                Go to Home
              </button>
            </div>
          </section>
        ) : paymentStatus === "Failed" ? (
          <PaymentFailure />
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};

export default PaymentSuccess;
