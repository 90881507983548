import React, { useEffect, useState, useRef } from "react";
import "./selectPetToHost.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
// import API from "./../../../axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Header from "../../components/header/Header";
import axios from "axios";
import { ApiBaseUrl } from "../../../../BaseUrl";

const SelectPetToHost = () => {
  const sliderRef = useRef(null);
  

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const navigate = useNavigate();
  const [petdata, setPetdata] = useState([]);
  const [nodata, setNodata] = useState(true);

  const [selectedPetIndex, setSelectedPetIndex] = useState(-1);

  const PARENT_ID = localStorage.getItem("PARENT_ID");

  const role = localStorage.getItem("ROLE");

  useEffect(() => {
    petapi();
  }, []);

  const petapi = async () => {
      
    // Retrieve the access token from local storage
    const accessToken = localStorage.getItem("ACCESS_TOKEN");

    // Set the bearer token in the request headers
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    try {
      let response;

      if (role === "PARENT") {
        response = await axios.get(`${ApiBaseUrl}/parent/${PARENT_ID}/pet`, config);
      } else if (role === "HOST") {
        response = await axios.get(`${ApiBaseUrl}/parent/${PARENT_ID}/pet`, config);
      } else {
        // Handle an invalid role here if needed
      }
      setPetdata(response.data);
      setNodata(true);
    } catch (error) {
      console.error(error);
    } finally {
      setNodata(false);
    }
  };

  const handleNextButtonClick = () => {
    const id = 2002;
    if (selectedPetIndex === -1) {
      // Show a toast message if no pet is selected
      toast.error("Please select a pet first");
      // alert("Please select a pet first.");
    } else if (selectedPetIndex === -91919191) {
      // Navigate to the next page
      navigate(`/add-pet`);
    } else {
      // sessionStorage.setItem("pet", selectedPetIndex);
      navigate(`/order-details`);
    }
  };

  return (
    <>
      <>
      <ToastContainer
      position="top-center"
      autoClose={1500}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
        <Header />

        <section className="main_choose_pet_section">
          <section className="choose_pet_to_host">
            <div className="heading">
              <h1>Select a pet to host</h1>
            </div>

            <div className="main_pet_container">
              <button className="slider_button previous" onClick={previous}>
                <ChevronLeftIcon />
              </button>
              <Slider ref={sliderRef} className="pets_container" {...settings}>
                {nodata ? (
                  <div></div>
                ) : petdata.length === 0 ? (
                  <div></div>
                ) : (
                  petdata.map((item, key) => {
                    return (
                      <>
                        <div
                          onClick={() => {
                            setSelectedPetIndex(key);
                            sessionStorage.setItem("pet", item.id); // Store the pet_id in sessionStorage
                          }}
                          key={key}
                          className={`pet_card ${
                            selectedPetIndex === key ? "selected" : ""
                          }`}
                        >
                          <div className="image_and_name">
                            <div className="image_container">
                              <img
                                src={`${ApiBaseUrl}${item.pet_images.profile_pic}`}
                                alt="My Pet"
                              />
                            </div>
                            <p className="pet_name">{item.name}</p>
                          </div>
                          <div className="more_info">
                            <p>
                              <span>Pet Type : </span>Dog
                            </p>
                            <p>
                              <span>Gender : </span>Male
                            </p>
                            <p>
                              <span>Weight : </span>10kg
                            </p>
                            <p>
                              <span>Behaviour : </span>Good
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })
                )}

                <div
                  onClick={() => {
                    setSelectedPetIndex(-91919191);
                  }}
                  className={`pet_card ${
                    selectedPetIndex === -91919191 ? "selected" : ""
                  }`}
                >
                  <div className="image_and_name">
                    <div className="image_container">
                      <img src="/assets/images/add-pet-icon.png" alt="My Pet" />
                    </div>
                    <p className="pet_name">Add New Pet</p>
                  </div>
                  <div
                    className="more_info"
                    style={{ alignItems: "center", paddingRight: "0" }}
                  >
                    <p>If you have to host a new pet,</p>
                    <p>kindly add it first.</p>
                  </div>
                </div>
              </Slider>
              <button className="slider_button next" onClick={next}>
                <ChevronRightIcon />
              </button>
             
            </div>
            <div className="next_page_button">
              <button onClick={handleNextButtonClick}>Next</button>
            </div>
          </section>
        </section>

        {/* <Footer /> */}
      </>
    </>
  );
};

export default SelectPetToHost;
