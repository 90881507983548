import React, { useEffect, useState } from "react";
import StarPurple500Icon from "@mui/icons-material/StarPurple500";
import FlagIcon from "@mui/icons-material/Flag";
import BlockIcon from "@mui/icons-material/Block";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import "./dashBoard.scss";
import axios from "axios";
import { ApiBaseUrl } from "../../../../BaseUrl";

// import {
//   BarChart,
//   Bar,
//   Cell,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";

const Dashboard = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  // const activeItem = data[activeIndex];

  const [dashboardData, setDashboardData] = useState([]);
  const [totalBooking, setTotalBooking] = useState("");
  const HOST_ID = localStorage.getItem("PARENT_ID");

  useEffect(() => {
    // Retrieve the access token from local storage
    const accessToken = localStorage.getItem("ACCESS_TOKEN");

    // Set the bearer token in the request headers
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios
      .get(`${ApiBaseUrl}/host/${HOST_ID}/totalbookings`, config)
      .then((resposne) => {
        // console.log("dashboard Data", reposne.data)
        // setDashboardData(resposne.data);
        setTotalBooking(resposne.data.orders_count);
      });
  }, []);

  return (
    <>
      <div className="dashboard">
        <h5>Business insights</h5>
        <h6>Number of bookings by processing times</h6>

        <div className="dashboard_data">
          <div className="totalBooking">
            <div>
              <LibraryBooksIcon className="dashboard_icon" />
            </div>
            <div className="dashboar_count">
              {totalBooking ? totalBooking : 0}
            </div>
            <div>
              <p>Total Bookings</p>
            </div>
          </div>
          <div className="totalBooking">
            <div>
              <BlockIcon className="dashboard_icon" />
            </div>
            <div className="dashboar_count">0</div>
            <div>
              <p>Cancelled Bookings</p>
            </div>
          </div>
          <div className="totalBooking">
            <div>
              <BlockIcon className="dashboard_icon" />
            </div>
            <div className="dashboar_count">0</div>
            <div>
              <p>Rejected Bookings</p>
            </div>
          </div>
          {/* <div className="totalBooking">
            <div>
              <FlagIcon className="dashboard_icon" />
            </div>
            <div className="dashboar_count">{dashboardData.total_credited}</div>
            <div>
              <p>Total Credit</p>{" "}
            </div>
          </div> */}
          <div className="totalBooking">
            <div>
              <StarPurple500Icon className="dashboard_icon" />
            </div>
            <div className="dashboar_count">0</div>
            <div>
              <p>Total Reviews</p>
            </div>
          </div>
        </div>
        {/* <BarChart width={400} height={400} className='mt-4 mb-4 barchart'   data={data}>
                <Bar dataKey="uv"  >
                    {data.map((entry, index) => (
                        <Cell
                            cursor="pointer"
                            fill={index === activeIndex ? "#82ca9d" : "#8884d8"}
                            key={`cell-${index}`}
                        />
                    ))}
                </Bar>
            </BarChart> */}
      </div>
    </>
  );
};

export default Dashboard;
