import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl } from "../../../BaseUrl";
const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
const PARENT_ID = localStorage.getItem("PARENT_ID");
const CONFIG = {
  headers: {
    Authorization: `Bearer ${ACCESS_TOKEN}`,
  },
};

// Action to Fectch Parent Profile Data::
export const fetchParentProfileData = createAsyncThunk(
  "fetchParentProfileData",
  async () => {
    const response = await axios.get(
      `${ApiBaseUrl}/parent/${PARENT_ID}`,
      CONFIG
    );
    return response.data;
  }
);

// Action to Fectch Parent Pets Data::
export const fetchParentPetsData = createAsyncThunk(
  "fetchParentPetsData",
  async () => {
    const response = await axios.get(
      `${ApiBaseUrl}/parent/${PARENT_ID}/pet`,
      CONFIG
    );
    return response.data;
  }
);

const parentProfileSlice = createSlice({
  name: "parentProfile",
  initialState: {
    isLoading: false,
    parentProfileData: null,
    parentPetsData: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchParentProfileData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchParentProfileData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.parentProfileData = action.payload;
    });
    builder.addCase(fetchParentProfileData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(fetchParentPetsData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchParentPetsData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.parentPetsData = action.payload;
    });
    builder.addCase(fetchParentPetsData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default parentProfileSlice.reducer;
