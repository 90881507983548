import React, { useEffect, useState } from "react";
import "./hostBookings.scss";
import axios from "axios";
import { ApiBaseUrl } from "../../../../BaseUrl";

const HostBookings = () => {
  const [myBooking, setMybooking] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState("");
  const PARENT_ID = localStorage.getItem("PARENT_ID");

  useEffect(() => {
    mybookingapi();
  }, []);

  const mybookingapi = () => {
    // Retrieve the access token from local storage
    const accessToken = localStorage.getItem("ACCESS_TOKEN");

    // Set the bearer token in the request headers
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios
      .get(`${ApiBaseUrl}/host/${PARENT_ID}/mybookings`, config)
      .then((response) => {
        setMybooking(response.data.orders);
        setLoading(false);
        // console.log("mybooking", response.data);
      });
  };

  return (
    <>
      <section>
        <div className="mybooking_host">
          <div className="mybooking-heading">
            <h4>My Bookings</h4>
          </div>
        </div>
        <div className="checkout_flex2_host">
          <div className="best-host-data">
            {myBooking.length === 0 ? (
              <p>No Bookings to Show</p>
            ) : myBooking && Array.isArray(myBooking) ? (
              myBooking.map((item) => {
                return (
                  <div className="best-host-inner" key={item.id}>
                    <div className="best_host_flex1">
                      <img
                        src={`${ApiBaseUrl}${item.parent.profile_pic}`}
                        alt=""
                      />
                    </div>
                    <div className="best_host_flex2">
                      <h6>{item.parent.name}</h6>
                      {/* <h6 className="role">{item.transaction.role}</h6> */}
                      {/* <h6>Amount pay: INR {item.transaction.amount} /-</h6> */}
                      {/* <h6>Transaction Id :{item.transaction.payment_id}</h6> */}
                      {/* <h6>{item.role}</h6> */}
                      <div className="timings">
                        <div className="start">
                          <p>
                            Start Date: <span>{item.start_date}</span>
                          </p>
                          <p>
                            Start Time: <span>{item.start_time}</span>
                          </p>
                          <p>
                            Phone: <span>{item.parent.mobile}</span>
                          </p>
                          <p style={{ marginTop: "0.5rem", fontSize: "14px" }}>
                            Payment:{" "}
                            <span>
                              {item.payment_status === 0
                                ? "Pending"
                                : item.payment_status === 1
                                ? "Pay Later"
                                : item.payment_status === 2
                                ? "Paid"
                                : ""}
                            </span>
                          </p>
                        </div>
                        <div className="end">
                          <p>
                            End Date: <span>{item.end_date}</span>
                          </p>
                          <p>
                            End Time: <span>{item.end_time}</span>
                          </p>
                          {/* <p>
                            Email: <span>{item.host.email}</span>
                          </p> */}
                        </div>
                      </div>
                      {/* <h6>Contact no.: {item.host.mobile}</h6> */}
                    </div>
                  </div>
                );
              })
            ) : (
              <h1></h1>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default HostBookings;
