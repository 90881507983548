import React from 'react'
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'

function HowWorkForParent() {
  return (
    <>
    <Header/>
    <div style={{padding: "140px 110px"}}>
    <h1>How It Works for Pet Parents</h1><br/>
    <p>Woofily makes it a walk in the park to find loving care for your furry family member. Whether you’re planning a getaway or just need a helping paw for the day, we’ve got you covered with trusted, verified hosts. Here’s how you can find the perfect stay for your pet!</p>

    <h3>1. Sign Up and Create Your Pet’s Profile</h3>
    <p>Start by creating your Woofily account—it’s as easy as a wag of a tail! Share a bit about yourself and your furry friend, including their name, breed, age, and any quirks or special needs. This helps our hosts get to know your pet better and provide the paw-fect care.</p>

    <h3>2. Sniff Out the Perfect Host</h3>
    <p>Once your profile is ready, it’s time to search for the ideal host who will treat your pet like royalty. With Woofily’s simple search tools, you can filter hosts by location, availability, and the type of care they offer—whether it’s a weekend stay or a daily doggy daycare.</p>
    <ul>
        <li><strong>Browse Paw-some Profiles:</strong> Check out host profiles to see their experience, services, and reviews from other pet parents. You’ll also find pictures of their pet-friendly homes, so you know your fur baby is in for a tail-wagging good time!</li>
        <li><strong>See Photos:</strong> Get a sneak peek at the cozy, safe space your pet will be staying in. Hosts love showing off their pet paradise!</li>
    </ul>

    <h3>3. Book with Peace of Mind</h3>
    <p>Found a host you and your pet love? Great! Send them a booking request and share all the details your host needs to know, like your pet’s favorite belly rub spot, meal preferences, and any special care instructions.</p>
    <ul>
        <li><strong>Secure Payments:</strong> All payments are handled securely through Woofily—no need to fetch your wallet every time! Your payment is only released after your pet’s stay, so you can feel confident that everything is in paw-fect order.</li>
    </ul>

    <h3>4. Drop-Off and Tail-Wagging Updates</h3>
    <p>Once your booking is confirmed, you can arrange to drop off your pet. Some hosts may even offer pick-up services for extra convenience. While you’re away, you’ll get regular updates, photos, and videos showing just how much fun your furry friend is having.</p>
    <ul>
        <li><strong>Real-Time Updates:</strong> No need to worry while you’re away! Your host will keep you updated with plenty of adorable photos and videos so you can see those happy tails and playful paws in action.</li>
    </ul>

    <h3>5. Pick-Up and Paw-some Reviews</h3>
    <p>When it’s time for pick-up, you’ll reunite with your pet, who’s sure to have had a paws-itively great time. After the stay, don’t forget to leave a review for your host, helping other pet parents find the best care for their fur babies.</p>
    <ul>
        <li><strong>Rate Your Experience:</strong> Did your host go above and beyond? Leave a review and share your experience! Your feedback helps keep our community full of happy tails and happy parents.</li>
    </ul>

    <h3>Why Woofily?</h3>
    <p>Woofily is more than just a pet-sitting platform—it’s a trusted community of animal lovers who treat your pets like family. Here’s why pet parents choose Woofily:</p>
    <ul>
        <li><strong>Verified Hosts:</strong> All Woofily hosts are carefully vetted with background checks and home verifications, so you can trust your pet will be in safe paws.</li>
        <li><strong>Tailored Care:</strong> Whether your pet needs extra belly rubs, medication, or a strict meal plan, Woofily hosts provide personalized care to make every stay a paws-itively perfect one.</li>
        <li><strong>Transparent Profiles:</strong> Check out host profiles with detailed information about their experience and services, so you can make an informed decision before booking.</li>
        <li><strong>Real-Time Communication:</strong> Stay in the loop with updates, photos, and messages from your host, so you always know your fur baby is doing great!</li>
    </ul>

    <h3>Woofily Support</h3>
    <p>Got a question or need help? Woofily’s support team is available 24/7, ready to lend a helping paw! Whether you’re booking your first stay or need help with a special request, we’re here to make things smooth and stress-free.</p>
</div>

    <Footer/>
      
    </>
  )
}

export default HowWorkForParent
