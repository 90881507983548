import React, { useEffect, useState } from "react";
import "./hostCard.scss";
import { IoPawSharp } from "react-icons/io5";
import { FaStar } from "react-icons/fa6";
import { ApiBaseUrl } from "../../../../BaseUrl";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Rate } from "antd";

const HostCard = ({
  key,
  id,
  name,
  role,
  profilePic,
  city,
  pricePerDay,
  pricePerHour,
  rating,
  distance,
}) => {
  const navigate = useNavigate();

  const openHostCard = (hostId) => {
    navigate(`/host/${hostId}`);
    // console.log(hostId)
  };

  const isUserLoggedIn = localStorage.getItem("isUserLoggedIn");

  // F U N C T I O N - T O - A D D - H O S T - T O - F A V O U R I T E S
  const [favIconColor, setFavIconColor] = useState("#000");
  const [favHosts, setFavHosts] = useState(() => {
    const storedFavHosts = localStorage.getItem("favHosts");
    return storedFavHosts ? JSON.parse(storedFavHosts) : [];
  });

  // useEffect to save favHosts state to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("favHosts", JSON.stringify(favHosts));
  }, [favHosts]);

  // Function to handle favorite hosts
  const HandleFavHosts = (HOST_ID) => {
    if (favHosts.includes(HOST_ID)) {
      // If host is already in favorites, remove it
      setFavHosts((prevFavHosts) =>
        prevFavHosts.filter((id) => id !== HOST_ID)
      );
    } else {
      // If host is not in favorites, add it
      setFavHosts((prevFavHosts) => [...prevFavHosts, HOST_ID]);
    }
  };

  const AddToFavourites = async (HOST_ID) => {
    HandleFavHosts(HOST_ID);
    try {
      const PARENT_ID = localStorage.getItem("PARENT_ID");
      // const HOST_ID = props.client_id;

      const accessToken = localStorage.getItem("ACCESS_TOKEN");
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await axios.post(
        `${ApiBaseUrl}/parent/${PARENT_ID}/${HOST_ID}/favorites`,
        config
      );
      if (favIconColor === "#000") {
        setFavIconColor("#FECA05");
      } else if (favIconColor === "#FECA05") {
        setFavIconColor("#000");
      }
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };

  return (
    <>
      <section className="host-card-wrapper flex-center">
        <div className="host-image-container ">
          <img
            src={`${ApiBaseUrl}${profilePic}`}
            alt=""
            onClick={() => openHostCard(id)}
          />
          {isUserLoggedIn ? (
            <div
              className="add-to-fav flex-center"
              style={
                favHosts.includes(key || id)
                  ? { backgroundColor: "#00000090" }
                  : { backgroundColor: "#fff" }
              }
              onClick={() => {
                AddToFavourites(id);
              }}
            >
              <IoPawSharp
                style={
                  favHosts.includes(key || id)
                    ? { color: "#FECA05" }
                    : { color: "#000" }
                }
                className="add-to-fav-icon"
              />
            </div>
          ) : (
            <div
              className="add-to-fav flex-center"
              style={{ backgroundColor: "#fff" }}
              onClick={() => {
                navigate("/login");
              }}
            >
              <IoPawSharp
                style={{ color: "#000" }}
                className="add-to-fav-icon"
              />
            </div>
          )}
        </div>
        <div
          className="host-info-container flex-center"
          onClick={() => openHostCard(id)}
        >
          <div className="host-info">
            <div className="ratings-wrapper flex-center">
              <div className="rating-stars">
                <Rate
                  disabled
                  allowHalf
                  defaultValue={rating === null ? 0 : rating}
                />
              </div>
              <div className="rating">&#40; {rating === null ? 0 : rating} &#41;</div>
            </div>
            <p className="name">{name}</p>
            <p className="profile">{role}</p>
            <p className="price">
              &#8377; {pricePerDay}
              <span>/day</span>{" "}
            </p>
            <p className="location">{city}</p>
          </div>
          <div className="book-now-button-wrapper flex-center">
            <button onClick={() => openHostCard(id)} id="book-now-button">
              Book Now
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default HostCard;
